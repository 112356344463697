// font-family
$SFPRODESPLAY: "SF Pro Display";
$SFPRODESPLAY_MEDIUM: "SF Pro Display_MEDIUM";
$SFPRODESPLAY_BOLD: "SF Pro Display_Bold";

// font-size
$FONT_64: 64px;
$FONT_60: 60px;
$FONT_57: 57px;
$FONT_48: 48px;
$FONT_45: 45px;
$FONT_37: 37px;
$FONT_36: 36px;
$FONT_35: 35px;
$FONT_32: 32px;
$FONT_30: 30px;
$FONT_28: 28px;
$FONT_24: 24px;
$FONT_22: 22px;
$FONT_20: 20px;
$FONT_18: 18px;
$FONT_16: 16px;
$FONT_14: 14px;
$FONT_12: 12px;

// Backgroud
$BG_WHITE: #FFFFFF;
$BG_PRIMARY: #0069B6;
$BG_SELECTED: #E5F0F8;
$BG_PRIMARY_50: #CCE1F0;
$BG_TOST_SUCCESS: #4BC249;
$BG_TOST_WARNING: #FFBA00;
$BG_TOST_ERROR: #FF4A26;
$BG_GRAY: #EDEDED;
$BG_OFWHITE: #F6F7FC;
$BG_BORDER: #D8D9DA;
$BG_DELETE: #FFD4CC;
$BG_EDIT_BACKGROUND:"#0069B60D";
$BG_ACCORDIAN_HEADER:"#E0F5FF";

// text color
$TEXT_PRIMARY: #0069B6;
$TEXT_PRIMARY_200: #80B4DB;
$TEXT_PRIMARY_300: #4D96CC;
$TEXT_PRIMARY_400: #2680C1;
$TEXT_SECONDARY: #777A7D;
$TEXT_SECONDARY_100: #C5C6C7;
$TEXT_SECONDARY_200: #9EA0A2;
$TEXT_SECONDARY_300: #777A7D;
$TEXT_SECONDARY_400: #595E61;
$TEXT_SECONDARY_500: #3C4145;
$TEXT_SECONDARY_600: #34393C;
$TEXT_SECONDARY_700: #2A2E30;
$TEXT_SECONDARY_800: #202325;
$TEXT_SECONDARY_900: #0F1011;
$TEXT_ERROR: #FF4A26;
$TEXT_ERROR_1: #FFBFB3;
$TEXT_ERROR_500: #FF2A00;
$TEXT_SUCCESS: #17B61F;
$TEXT_WHITE: #FFFFFF;
$TEXT_SUCCESS_400: #4BC249;
$TEXT_WARNING: #FFBA00;
