* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "SF Pro Display" !important;
}



@font-face {
  font-family: 'SF Pro Display';
  src: url('./Assets/Font/SFProDisplay-Regular.eot');
  src: url('./Assets/Font/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Assets/Font/SFProDisplay-Regular.woff2') format('woff2'),
      url('./Assets/Font/SFProDisplay-Regular.woff') format('woff'),
      url('./Assets/Font/SFProDisplay-Regular.ttf') format('truetype'),
      url('./Assets/Font/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display_MEDIUM';
  src: url('./Assets/Font/SFProDisplay-Medium.eot');
  src: url('./Assets/Font/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
      url('./Assets/Font/SFProDisplay-Medium.woff2') format('woff2'),
      url('./Assets/Font/SFProDisplay-Medium.woff') format('woff'),
      url('./Assets/Font/SFProDisplay-Medium.ttf') format('truetype'),
      url('./Assets/Font/SFProDisplay-Medium.svg#SFProDisplay-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display_Bold';
  src: url('./Assets/Font/SFProDisplay-Bold.eot');
  src: url('./Assets/Font/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('./Assets/Font/SFProDisplay-Bold.woff2') format('woff2'),
      url('./Assets/Font/SFProDisplay-Bold.woff') format('woff'),
      url('./Assets/Font/SFProDisplay-Bold.ttf') format('truetype'),
      url('./Assets/Font/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'sf_pro_displayregular';
//   src: url('./Assets/Font/sfprodisplayregular-webfont.woff2') format('woff2'),
//        url('./Assets/Font/sfprodisplayregular-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }
// @font-face {
//     font-family: 'sf_pro_displaaymedium';
//     src: url('./Assets/Font/sfprodisplaymedium-webfont.woff2') format('woff2'),
//          url('./Assets/Font/sfprodisplaymedium-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }
// @font-face {
//   font-family: 'sf_pro_displaybold';
//   src: url('./Assets/Font/sfprodisplaybold-webfont.woff2') format('woff2'),
//        url('./Assets/Font/sfprodisplaybold-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'Aviagen';
//   src:  url('./Assets/Font/Aviagen.eot?37wkyz');
//   src:  url('./Assets/Font/Aviagen.eot?37wkyz#iefix') format('embedded-opentype'),
//     url('./Assets/Font/Aviagen.ttf?37wkyz') format('truetype'),
//     url('./Assets/Font/Aviagen.woff?37wkyz') format('woff'),
//     url('./Assets/Font/Aviagen.svg?37wkyz#Aviagen') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

// [class^="icon-"], [class*=" icon-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'Aviagen' !important;
//   speak: never;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

@font-face {
  font-family: 'Aviagen';
  src:  url('./Assets/Font/Aviagen.eot?vlm106');
  src:  url('./Assets/Font/Aviagen.eot?vlm106#iefix') format('embedded-opentype'),
    url('./Assets/Font/Aviagen.ttf?vlm106') format('truetype'),
    url('./Assets/Font/Aviagen.woff?vlm106') format('woff'),
    url('./Assets/Font/Aviagen.svg?vlm106#Aviagen') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Aviagen' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Circle_Check:before {
  content: "\e900";
}
.icon-Circle_Warning:before {
  content: "\e901";
}
.icon-User_01:before {
  content: "\e902";
}
.icon-User_03:before {
  content: "\e903";
}
.icon-User_Add:before {
  content: "\e904";
}
.icon-Users:before {
  content: "\e905";
}
.icon-Close_LG:before {
  content: "\e906";
}
.icon-Close_MD:before {
  content: "\e907";
}
.icon-Hamburger_LG:before {
  content: "\e908";
}
.icon-More_Vertical:before {
  content: "\e909";
}
.icon-Check:before {
  content: "\e90a";
}
.icon-Download:before {
  content: "\e90b";
}
.icon-Heart_01:before {
  content: "\e90c";
}
.icon-Lock:before {
  content: "\e90d";
}
.icon-Log_Out:before {
  content: "\e90e";
}
.icon-Main_Component:before {
  content: "\e90f";
}
.icon-Search_Magnifying_Glass:before {
  content: "\e910";
}
.icon-Trash_Empty:before {
  content: "\e911";
}
.icon-Trash_Empty-1:before {
  content: "\e912";
}
.icon-Trash_Full:before {
  content: "\e913";
}
.icon-Archive:before {
  content: "\e914";
}
.icon-Cloud_Upload:before {
  content: "\e915";
}
.icon-File_Document:before {
  content: "\e916";
}
.icon-Files:before {
  content: "\e917";
}
.icon-Add_Plus:before {
  content: "\e918";
}
.icon-Close_Circle:before {
  content: "\e919";
}
.icon-Edit_Pencil_02:before {
  content: "\e91a";
}
.icon-Edit_Pencil_02-1:before {
  content: "\e91b";
}
.icon-Hide:before {
  content: "\e91c";
}
.icon-Show:before {
  content: "\e91d";
}
.icon-Bell_Notification:before {
  content: "\e91e";
}
.icon-Mail:before {
  content: "\e91f";
}
.icon-Share_iOS_Export:before {
  content: "\e920";
}
.icon-Calendar_Days:before {
  content: "\e921";
}
.icon-Chevron_Right-1:before {
  content: "\e922";
}
.icon-Unfold_More:before {
  content: "\e923";
}
.icon-Arrow_Right_MD:before {
  content: "\e924";
}
.icon-Chevron_Down:before {
  content: "\e925";
}
.icon-Chevron_Right:before {
  content: "\e926";
}
.icon-Fill_Info:before {
  content: "\e927";
}
.icon-Fill_Close:before {
  content: "\e928";
}
.icon-Fill_Check:before {
  content: "\e929";
}
.icon-Warning-Toast:before {
  content: "\e92a";
}
.icon-User_Voice:before {
  content: "\e92b";
}
.icon-User_toggle:before {
  content: "\e92c";
}
.icon-FilledHeart:before {
  content: "\e92d";
}
.icon-Arrow_Circle_Up:before {
  content: "\e92e";
}
.icon-BellOnly:before {
  content: "\e92f";
}
.icon-Biosecurity:before {
  content: "\e930";
}
.icon-Ventilation:before {
  content: "\e931";
}
.icon-Housing:before {
  content: "\e932";
}
.icon-Myopathies:before {
  content: "\e933";
}
.icon-Feed-Nutrition:before {
  content: "\e934";
}
.icon-Freight:before {
  content: "\e935";
}
.icon-Health:before {
  content: "\e936";
}
.icon-Males:before {
  content: "\e937";
}
.icon-Brooding:before {
  content: "\e938";
}
.icon-Eggs:before {
  content: "\e939";
}
.icon-Females:before {
  content: "\e93a";
}
.icon-Fertility:before {
  content: "\e93b";
}
.icon-Hatchery:before {
  content: "\e93c";
}
.icon-Quality:before {
  content: "\e93d";
}
.icon-Lighting:before {
  content: "\e93e";
}
.icon-Water:before {
  content: "\e93f";
}
.icon-Welfare:before {
  content: "\e940";
}
.icon-Gut_Health:before {
  content: "\e941";
}
.icon-Sustainability:before {
  content: "\e942";
}
.icon-Transfer:before {
  content: "\e943";
}
.icon-Vaccination:before {
  content: "\e944";
}
.icon-Processing:before {
  content: "\e945";
}
.icon-Feathering:before {
  content: "\e946";
}
.icon-Legs:before {
  content: "\e947";
}
.icon-Equipment:before {
  content: "\e948";
}
.icon-Performance:before {
  content: "\e949";
}
.icon-Maintenance:before {
  content: "\e94a";
}
.icon-Litter:before {
  content: "\e94b";
}
.icon-Economics:before {
  content: "\e94c";
}
.icon-Uniformity:before {
  content: "\e94d";
}
.icon-Veterinary:before {
  content: "\e94e";
}
.icon-Breeder_Rearing:before {
  content: "\e94f";
}
.icon-Breeder_Production:before {
  content: "\e950";
}
.icon-FCR:before {
  content: "\e951";
}
.icon-Group:before {
  content: "\e952";
  color: #006fba;
}