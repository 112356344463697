@import '../../../Assets/scss/variable.scss';

.textField {
  position: relative;
  max-width: 100%;
  width: 100%;
  display: block;
  margin-bottom: 16px;
  float: left;
  
  .form-control {
    background: $BG_WHITE;
    color: $TEXT_SECONDARY_600;
    font-size: $FONT_16;
    padding: 16px 16px 16px 14px;
    height: 48px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid $TEXT_SECONDARY_700;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: normal;
    font-family: $SFPRODESPLAY_MEDIUM;
    
    &:focus {
      outline: none;
      border-color: $TEXT_SECONDARY_700;
      box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -webkit-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -moz-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -ms-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -o-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
    }

    &:focus-visible {
      text-shadow: none;
    }

    &::placeholder {
      color: $TEXT_SECONDARY_100;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $TEXT_SECONDARY_100;
    }

    &::-ms-input-placeholder {
      color: $TEXT_SECONDARY_100;
    }
  }

  .iconRight {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 16px 0 20px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $FONT_24;
    color: $TEXT_SECONDARY_600;
  }

  .iconSection {
    padding-right: 16px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $FONT_24;
    color: $TEXT_SECONDARY_600;
    i {
      padding: 3px;
      cursor: pointer;
    }
  }

  .errorMsg {
    position: relative;
    width: 100%;
    left: 0;
    display: block;
    font-size: $FONT_14;
    text-align: left;
    padding: 4px 0;
    margin: 0;
    color: $TEXT_WHITE;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }

  .errorMsg.show {
    max-height: 130px;
    transition: max-height 0.3s ease-in;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    color: $TEXT_ERROR;
    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        font-size: $FONT_16;
        margin-right: 4px;
      }
    }
    .red,
    .green {
      display: flex;
      padding: 2px 0;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }

    .red {
      span {
        color: $TEXT_ERROR;
      }
    }

    .green {
      span {
        color: $TEXT_SUCCESS;
      }
    }
  }
}
