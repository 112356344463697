@import '../../../Assets/scss/variable.scss';

.headerAdmin {
  width: 100%;
  float: left;
  position: relative;

  .header {
    width: 200px;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    background-color: #ffffff;
    box-shadow: none;

    .navbar {
      display: block;
      padding: 0;

      .navbar-brand {
        padding: 0 0 10px 0;
        margin: 0;
        display: block;
        width: 100%;
        float: left;
        text-align: center;
        // min-height: 84px;
        border-bottom: 1px solid $BG_GRAY;

        &:focus {
          outline: none;
        }

        img {
          position: relative;
          max-width: 100%;
          height: auto;
          display: inline-block;
        }
      }

      .navbar-collapse {
        display: block !important;
        width: 100%;
        float: left;
        padding: 16px;
        height: 100%;

        .navbar-nav {
          flex-direction: column;
          width: 100%;
          float: left;
          justify-content: flex-start;
          height: 100%;
          align-items: center;

          .navTabMain {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            width: 100%;
            padding-top: 24px;

            .navTab {
              width: 148px;
              margin: 0 auto;
            }

            .nav-link {
              font-family: $SFPRODESPLAY_MEDIUM;
              font-size: $FONT_14;
              color: $TEXT_SECONDARY_800;
              text-align: left;
              display: flex;
              align-items: center;
              padding: 16px;
              margin-bottom: 4px;
              width: 100%;
              float: left;
              border-radius: 50px;
              justify-content: center;
              border-radius: 10px;
              margin-bottom: 8px;

              &.active {
                box-shadow: 0px 5px 36px rgba(0, 0, 0, 0.07);

                .linkBtn {
                  color: $TEXT_PRIMARY !important;
                }
              }

              &:hover {
                box-shadow: 0px 5px 36px rgba(0, 0, 0, 0.07);

                .linkBtn {
                  color: $TEXT_PRIMARY !important;
                }
              }

              .linkBtn {
                width: 93px;
                padding: 0 !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $BG_WHITE !important;
                color: $TEXT_SECONDARY_200 !important;
                font-size: $FONT_16 !important;
                font-family: $SFPRODESPLAY_MEDIUM !important;

                span {
                  padding-top: 10px;
                }

                i {
                  margin-right: 0 !important;
                  font-size: $FONT_30 !important;
                  position: relative;

                  &.notification {
                    &::after {
                      content: '';
                      position: absolute;
                      top: 0px;
                      right: 2px;
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      background-color: $TEXT_PRIMARY;
                    }
                  }
                }
              }

              i {
                margin-right: 16px;
                font-size: $FONT_20;
              }
            }

            .notificationBorder {
              height: 1px;
              margin-bottom: 8px;
              background-color: #EDEDED;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .profileTab {
              width: 100%;
              padding: 16px;

              .content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .dropdown {
                  padding: 0;
                  width: 100%;

                  .dropdown-toggle {
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    font-size: $FONT_16;
                    font-family: $SFPRODESPLAY_MEDIUM;
                    color: $TEXT_SECONDARY_200;
                    width: 100%;

                    &:focus {
                      box-shadow: none;
                    }

                    &::after {
                      font-family: 'Aviagen' !important;
                      content: '\e926';
                      border: none;
                      font-size: $FONT_16;
                      // vertical-align: 0;
                      position: relative;
                      // top: 2px;
                    }

                    .sidebarProfile {
                      width: 86px;
                      height: 86px;
                      margin: 0 auto 12px auto;
                      border-radius: 50%;

                      img {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }

                    }

                    span {
                      display: inline-block;
                      max-width: 120px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }

                  .dropdown-menu {
                    position: absolute;
                    left: 170px;
                    top: auto;
                    bottom: -10px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    width: 162px;
                    border: none;
                    margin: 0;
                    padding: 4px;
                    min-width: 162px;

                    .dropdown-item {
                      display: flex;
                      align-items: center;
                      padding: 8px;
                      font-size: $FONT_14;
                      color: $TEXT_SECONDARY_600;
                      font-weight: 400;

                      &:active {
                        background-color: transparent;
                      }

                      &:first-child {
                        border-radius: 10px 10px 0 0;
                      }

                      &:last-child {
                        border-radius: 0px 0px 10px 10px;

                        i {
                          color: $TEXT_ERROR_500;
                        }
                      }

                      i {
                        font-size: $FONT_16;
                        margin-right: 8px;
                        color: $TEXT_PRIMARY;
                      }
                    }
                  }
                }

                .btn {
                  min-width: 100px;
                  padding: 6px 12px !important;
                  min-height: 35px;
                }
              }
            }
          }
        }
      }

      .profiledropdown {
        width: -moz-calc(100% - 200px);
        width: -webkit-calc(100% - 200px);
        width: -o-calc(100% - 200px);
        width: calc(100% - 200px);
        width: expression(100% - 200px);
        position: fixed;
        right: 0;
        top: 0;
        text-align: right;
        background-color: $BG_WHITE;
        box-shadow: none;
        padding: 16px 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $BG_PRIMARY;
        min-height: 80px;

        .cloudUploadBtn {
          height: 48px;
          width: 198px;
          border-radius: 10px !important;
          padding: 14px 16px !important;
          background-color: $BG_WHITE !important;
          color: $TEXT_SECONDARY_900 !important;

          i {
            color: $TEXT_PRIMARY !important;
          }
        }
      }
    }
  }

  .outside {
    width: calc(100% - 200px);
    height: 100%;
    position: absolute;
    z-index: 8;
    content: '';
    background-color: goldenrod;
    top: 0;
    right: 0;
    display: none;
  }
}

.modal {
  &.candidateProfile {
    .modal-dialog {
      max-width: 700px;

      .modal-content {
        .modalInner {
          width: 100%;
          position: relative;
          float: left;

          .modalHeader {
            float: right;

            .modalClosebtn {
              width: 42px;
              height: 42px;
              background-color: $BG_WHITE;
              border-radius: 50px !important;
              border: 2px solid #EDEDED !important;
              padding: 0 !important;
              justify-content: center;
              background: $BG_WHITE;

              i {
                margin-right: 0 !important;
                color: $TEXT_PRIMARY;
                font-size: $FONT_14;
              }

              &:hover {
                border: 2px solid $BG_PRIMARY !important;
                background: $BG_PRIMARY;

                i {
                  color: $TEXT_WHITE;
                }
              }
            }
          }

          .modalBody {
            float: left;
            width: 100%;

            .formMain {
              padding: 100px 0;
              max-width: 375px;
              margin: 0 auto;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              .CandidateImageMain {
                margin-bottom: 48px;

                .uploadMain {
                  width: 100%;

                  .uploadBox {
                    width: 87px;
                    height: 87px;
                    border-radius: 50%;
                    margin: 0 auto;

                    .imageBoxEmpty,
                    .uploaded {
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      position: relative;

                      &:hover {
                        &::before {
                          content: 'Upload';
                          color: $TEXT_WHITE;
                          font-family: $SFPRODESPLAY_MEDIUM;
                          font-size: $FONT_16;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: absolute;
                          left: 0;
                          top: 0;
                          z-index: 9;
                          height: 87px;
                          width: 87px;
                          border-radius: 50%;
                          background: rgba(0, 105, 182, 0.5);
                        }
                      }

                      img {
                        width: 100%;
                        border-radius: 50%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }

                    .imageBoxEmpty {
                      position: relative;

                      .hoverUpload {
                        display: none !important;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(0, 105, 182, 0.4);
                        color: $TEXT_WHITE;
                        font-size: $FONT_12;
                        font-family: $SFPRODESPLAY;
                        font-weight: 400;
                      }

                      &:hover {
                        .hoverUpload {
                          display: flex !important;
                        }
                      }
                    }
                  }
                }

                .txtUpload {
                  text-align: center;
                  font-size: $FONT_14;
                  font-weight: 400;
                  margin-top: 10px;
                  display: none;
                }

                h5 {
                  margin-top: 24px;
                  margin-bottom: 0;
                  font-size: $FONT_22;
                  font-family: $SFPRODESPLAY;
                  font-weight: 400;
                  color: $TEXT_SECONDARY_600;
                }
              }

              .updateBtn {
                width: 100% !important;
                margin-bottom: 10px;
              }

              .cancelBtn {
                background-color: $BG_WHITE !important;
                color: $TEXT_PRIMARY !important;
                border: 2px solid #EDEDED !important;
                width: 100%;
              }
            }

            .bottomContent {
              display: flex;
              flex-direction: column;
              margin-top: 80px;

              .custombtn {
                padding: 0 !important;
                background-color: transparent;
                min-height: auto !important;
                font-size: $FONT_16;
                font-family: $SFPRODESPLAY;
                font-weight: 400;
              }

              .changePasswordBtn {
                color: $TEXT_PRIMARY !important;
                margin-bottom: 10px;
              }

              .logoutBtn {
                color: $BG_TOST_ERROR !important;
              }
            }
          }
        }
      }
    }
  }

  &.notificationModal {
    overflow: hidden;

    .modal-dialog {
      max-width: 700px;

      .modal-content {
        .modalInner {
          width: 100%;
          position: relative;
          float: left;

          .modalHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 18px;

            h2 {
              margin: 0;
              font-size: $FONT_18;
              color: $TEXT_SECONDARY_600;
              font-family: $SFPRODESPLAY_MEDIUM;
            }

            .modalClosebtn {
              width: 42px;
              height: 42px;
              background-color: $BG_WHITE;
              border-radius: 50px !important;
              border: 2px solid #EDEDED !important;
              padding: 0 !important;
              justify-content: center;
              background: $BG_WHITE;

              i {
                margin-right: 0 !important;
                color: $TEXT_PRIMARY;
                font-size: $FONT_14;
              }

              &:hover {
                border: 2px solid $BG_PRIMARY !important;
                background: $BG_PRIMARY;

                i {
                  color: $TEXT_WHITE;
                }
              }
            }

            .modalHeader-btn {
              display: flex;

              & button:last-child {
                margin-left: 5px;
              }
            }
          }

          .modalBody {
            float: left;
            width: 100%;

            .main {
              width: 100%;
              float: left;
              overflow-y: hidden;
              max-height: 700px;
              scrollbar-width: 0;

              &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 0;
                height: 0 !important;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                -ms-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                -o-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
              }

              h1 {
                margin: 16px 0;
                text-align: center;
                font-size: $FONT_14;
                font-family: $SFPRODESPLAY_MEDIUM;
                color: $TEXT_PRIMARY;
              }

              .notificationCard {
                background: $BG_WHITE;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                padding: 16px 20px;
                margin-bottom: 12px;

                .text {
                  font-size: $FONT_16;
                  font-family: $SFPRODESPLAY_MEDIUM;
                  color: $TEXT_SECONDARY_700;
                  text-align: left;
                }

                .detail {
                  display: flex;
                  justify-content: space-between;

                  .left {
                    width: calc(100% - 80px);
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .documentType {
                      width: 40px;
                      height: 40px;

                      img {
                        width: 100%;
                        height: 100%;
                      }
                    }

                    .textDetail {
                      display: flex;
                      flex-direction: column;
                      padding-left: 15px;

                      .ducumentName {
                        display: flex;
                        align-items: center;

                        .text {
                          margin: 0;
                          font-size: $FONT_16;
                          color: $TEXT_SECONDARY_300;
                          font-family: $SFPRODESPLAY_MEDIUM;
                        }

                        span {
                          padding-left: 16px;
                          font-size: $FONT_16;
                          color: $TEXT_SECONDARY_300;
                          font-family: $SFPRODESPLAY_MEDIUM;
                        }
                      }

                      .subText {
                        margin: 0;
                        font-size: $FONT_14;
                        color: $TEXT_PRIMARY;
                        font-family: $SFPRODESPLAY_MEDIUM;
                      }

                      .expireDate {
                        margin: 0;
                        font-size: $FONT_14;
                        color: $TEXT_ERROR_500;
                        font-family: $SFPRODESPLAY_MEDIUM;
                      }
                    }
                  }

                  .right {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    min-width: 60px;
                    max-width: 80px;

                    p {
                      margin: 0;
                      font-size: $FONT_14;
                      color: $TEXT_SECONDARY_100;
                      font-family: $SFPRODESPLAY;
                    }
                  }
                }
              }
            }
          }
        }

        .mark-read-all {
          width: max-content;
        }
      }
    }
  }
}

.confirmModal {
  &.logOutPopup {
    .modal-dialog {
      max-width: 335px;

      .modal-content {
        .modal-footer {
          .cancelBtn {
            background-color: $BG_TOST_ERROR !important;
            color: $TEXT_WHITE !important;
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .modal {
    &.candidateProfile {
      .modal-dialog {
        .modal-content {
          .modalInner {
            .modalHeader {}

            .modalBody {
              .formMain {
                padding: 50px 0;

                .CandidateImageMain {
                  margin-bottom: 24px;

                  .txtUpload {
                    display: block;
                  }
                }

                .cancelBtn {}
              }

              .bottomContent {
                margin-top: 152px;

                .custombtn {
                  padding: 0 !important;
                  background-color: transparent;
                  min-height: auto !important;
                  font-size: $FONT_16;
                  font-family: $SFPRODESPLAY;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 425px) and (max-width: 1080px) and (orientation:landscape) {
  .modal {
    &.candidateProfile {
      .modal-dialog {
        .modal-content {
          .modalInner {
            .modalHeader {
              display: none;
            }

            .modalBody {
              .formMain {
                .CandidateImageMain {
                  margin-bottom: 24px;

                  .txtUpload {
                    display: block;
                  }
                }

                .cancelBtn {
                  display: none;
                }
              }

              .bottomContent {
                margin-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:1279px) {

  .headerAdmin .header .navbar .profiledropdown {
    z-index: 2;

    h3 {
      margin-left: 60px;
    }
  }

  .header .navbar .navbar-collapse.show {
    z-index: 9;
  }
}