@import '../../../Assets/scss//variable.scss';

.confirmModal {
  &.cancelModal{
    .modal-dialog {
      width: 335px;
      max-width: 100%;
      margin: 0 auto;
  
      .modal-content {
        .modal-footer {
          padding: 0;
          border: 0;
          border-radius: 0;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-wrap: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
          .custombtn {
            width: auto;
            padding: 0 !important;
            margin: 0;
            font-size: $FONT_16 !important;
            padding: 10px !important;
            margin-right: 10px;
            min-height: 39px !important;
            border-radius: 50px !important;
            color: $TEXT_PRIMARY !important;
            background-color: $BG_WHITE;
            min-width: 98px;
            border-radius: 10px !important;
            border: 1px solid $BG_GRAY !important;
            padding: 14px 30px !important;
            &:hover{
                background-color: transparent !important;
                color: $TEXT_PRIMARY !important;
            }
            &.cancelBtn{
              background-color: $TEXT_ERROR_500 !important;
              color: $TEXT_WHITE !important;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .modal-dialog {
    width: 608px;
    max-width: 100%;
    margin: 0 auto;

    .modal-content {
      border: 0;
      border-radius: 10px;  
      overflow: hidden;
      padding: 20px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      -ms-box-shadow:0px 3px 15px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      h3{
        text-align: center;
        font-size: $FONT_24;
        font-weight: 700;
        margin: 0;
        color: $TEXT_SECONDARY_800;
        font-family: $FONT_22;
        font-weight: 400;
        font-family: $SFPRODESPLAY_MEDIUM;
        margin-bottom: 10px;
      }
      p {
        margin: 0;
        text-align: center;
        font-family: $SFPRODESPLAY_MEDIUM;
        font-size: $FONT_16;
        color: $TEXT_SECONDARY_400;
        margin-bottom: 32px;
      }

      i {
        margin: 0 auto;
        font-size: 48px;
      }

      .modal-footer {
        padding: 0;
        border: 0;
        border-radius: 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        .custombtn {
          width: auto;
          padding: 0 !important;
          margin: 0;
          font-size: $FONT_16 !important;
          padding: 10px !important;
          margin-right: 10px;
          min-height: 39px !important;
          border-radius: 50px !important;
          color: $TEXT_PRIMARY !important;
          background-color: $BG_WHITE;
          min-width: 98px;
          border-radius: 10px !important;
          border: 1px solid $BG_GRAY !important;
          padding: 14px 30px !important;
          &.cancelBtn:hover{
            
          }
          &:hover{
              background-color: $BG_PRIMARY !important;
              color: $TEXT_WHITE !important;
          }
        }
      }
    }
  }
}