@import '../../../Assets/scss/variable.scss';

.toast {
  position: fixed;
  top: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
  text-align: center;
  flex-basis: inherit !important;
  margin: 0 auto !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 20px 0 0 0;
  display: none;
  width: inherit !important;
  font-family: $SFPRODESPLAY_MEDIUM !important;
  


  &.success-txt,
  &.error-txt,
  &.warning-txt {
    .tostcenter {
      .leftIcon{
        padding: 0 15px;
        &::before{
          content: ' ' ;
          position: absolute;
          left: 8px;
          top: 8px;
          height: calc(100% - 16px) !important;
          padding: 3px;
          border-radius: 5px;
        }
        i{
          font-size: $FONT_30;
        }
      }
      .type {
        font-size: $FONT_22;
        color: $TEXT_SECONDARY_800;
        margin-bottom: 8px;
      }
      .tostMassage {
        font-size: $FONT_16;
        color: $TEXT_SECONDARY_400;
        line-height: 22px;
      }
    }
  }
  &.success-txt{
    .tostcenter{
      .leftIcon{
        &::before{
          background-color: $BG_TOST_SUCCESS;
        }
        i{
            color: $BG_TOST_SUCCESS;
        }
      }
    }
  }
  &.warning-txt{
    .tostcenter{
      .leftIcon{
        &::before{
          background-color: $BG_TOST_WARNING;
        }
        i{
            color: $BG_TOST_WARNING;
        }
      }
    }
  }
  &.error-txt{
    .tostcenter{
      .leftIcon{
        &::before{
          background-color:  $BG_TOST_ERROR;
        }
        i{
            color: $BG_TOST_ERROR;
        }
      }
    }
  }
  .tostcenter {
    display: flex;
    align-items: center;
    min-width: 375px;
    max-width: 416px;
    padding: 8px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: $BG_WHITE;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    p {
      font-weight: normal;
      text-align: left;
      margin: 0px;
      font-family: $SFPRODESPLAY_MEDIUM;
      font-size: $FONT_14;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &.show {
    display: block;
  }
}