@import "../../../Assets//scss/variable.scss";

.full {
  margin: auto;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.95);
  .innerDiv{
    position: relative;
    img {
      width: 70px;
      position: relative;
    }
    .blinkImage{
      position: absolute;
      bottom: 0;
      right: 20px;
      width: 8px;
      height: 8px;
    }
  }

  div {
    margin: auto;
  }
}
.blinkImage {
  -moz-animation: blink normal 0.7s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 0.7s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 0.7s infinite ease-in-out; /* IE */
  animation: blink normal 0.7s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

@-moz-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 

@-webkit-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
