@import "../../../Assets/scss/variable.scss";

.modal {
  .modal-content {
    margin: 0 20px;
    border: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow:  0px 3px 15px rgba(0, 0, 0, 0.2);
    -moz-box-shadow:  0px 3px 15px rgba(0, 0, 0, 0.2);
    -ms-box-shadow:  0px 3px 15px rgba(0, 0, 0, 0.2);
    -o-box-shadow:  0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    position: relative;

    .icon-cross {
      position: absolute;
      right: 20px;
      top: 24px;
      cursor: pointer;
      color: $TEXT_SECONDARY_600;
      font-size: $FONT_24;
      z-index: 9;
    }
  }
}
@media screen and (max-width: 767px){
  .modal {
    &.viewDocumentModal{
      .modal-dialog{
        max-width: 700px !important;
        .modal-content{
          margin: 0;
          padding: 10px !important;
          .modalInner{
            .modalHeader{
              .leftPart{
                .btn.iconBtn{
                }
                .iconBtn  {
                  &.iconBtn{
                    height: 40px !important;
                    width: 40px;
                    padding: 0 !important;
                    border-radius: 50px !important;
                  }
                  i{
                    margin-right: 0;
                  }
                  span{
                    display: none;
                  }
                }
              }
            }
            .modalBody{
              height: 498px !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px){
  .modal {
    &.viewModal, &.downloadModal{
      .modal-dialog {
        max-width: 651px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
              margin-bottom: 18px !important;
              p{
                font-size: $FONT_18 !important;
              }
            }
            .modalBody{
              .searchBar{
                max-width: 450px !important;
              }
              .fileInfoBar{
                ul{
                  li{
                    .fileName{
                      font-size: $FONT_18 !important;
                    }
                    .authorName{
                      font-size: $FONT_14 !important;
                    }
                    .data{
                      font-size: $FONT_16 !important;
                    }
                  }
                }
              }
              .table-content{
                .table-scroll{
                  max-height: 272px !important;
                }
              }
            }
          }
        }
      }
    }
    &.grantedModal{
      .modal-dialog{
          max-width: 650px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
                margin-bottom: 20px !important;
                p{
                    margin: 0;
                    font-size: $FONT_18 !important;
                }
              .modalClosebtn{
                width: 34px;
                height: 34px;
              }
            }
            .modalBody{
                float: left;
                width: 100%;
                .fileInfoBar{
                    margin-bottom: 18px !important;
                    p{
                        margin: 0;
                    }
                    ul{
                        li{
                            margin-right: 20px  !important;
                            .fileImg{
                                width: 40px;
                                height: 40px;
                            }
                            .fileName{
                                font-size: $FONT_18 !important;
                                color: $TEXT_SECONDARY_900;
                                font-family: $SFPRODESPLAY;
                            }
                        }
                    }
                }
                .searchBar{
                    margin-bottom: 18px !important;
                    max-width: 450px !important;
                    width: 100%;
                }
                .table-content{
                    .table-scroll{
                        max-height: 400px !important;
                        table{
                            min-width: 100%;
                            thead tr{
                                th{
                                  font-size: $FONT_14 !important;
                                    vertical-align: middle;
                                    div{
                                        display: flex;
                                        align-items: center;
                                        p{
                                            margin: 0;
                                        }
                                    }
                                    &:nth-child(3){
                                      min-width: 170px;
                                      width: 170px;
                                    }
                                    &:last-child{
                                      .action{
                                        display: none;
                                      }
                                      .expier{
                                          display: block !important;
                                      }
                                    }
                                }
                            }
                            tbody tr td{
                                padding: 8px 12px !important;
                                font-size: $FONT_14;
                                .email{
                                  font-size: $FONT_12;
                                }
                                &:nth-child(3){
                                  p{
                                    max-width: 170px;
                                  }
                                }
                            } 
                        }
                    }
                }
            }
          }
        }
      }
    }
    &.requestedModal{
      .modal-dialog{
          max-width: 650px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
                margin-bottom: 20px !important;
                p{
                    font-size: $FONT_18 !important;
                }
              .modalClosebtn{
                width: 34px !important;
                height: 34px !important;
              }
            }
            .modalBody{
                .fileInfoBar{
                    margin-bottom: 18px !important;
                    p{
                        margin: 0;
                    }
                    ul{
                        li{
                          margin-right: 20px !important;
                            .fileName{
                                font-size: $FONT_18 !important;
                            }
                        }
                    }
                }
                .searchBar{
                    margin-bottom: 18px !important;
                    max-width: 450px !important;
                    width: 100%;
                }
                .table-content{
                    .table-scroll{
                        max-height: 400px !important;
                        table{
                            min-width: 100%;
                            thead tr{
                                th{
                                  font-size: $FONT_14 !important;
                                    vertical-align: middle;
                                    &:last-child{
                                      width: 60px;
                                      min-width: 60px;
                                      div{
                                        display: none;
                                      }
                                      div{
                                        p{
                                          display: none;
                                        }
                                      }
                                    }
                                    &:nth-child(3){
                                      width: 160px;
                                      min-width: 160px;
                                    }
                                    div{
                                        display: flex;
                                        align-items: center;
                                        p{
                                            margin: 0;
                                        }
                                      }
                                }
                            }
                            tbody tr td{
                                padding: 8px 12px !important;
                                font-size: $FONT_14;
                                .email{
                                  font-size: $FONT_12;
                                }
                                &:nth-child(3){
                                  width: 160px;
                                  min-width: 160px;
                                }
                            } 
                        }
                    }
                }
            }
          }
        }
      }
    }
    &.viewDocumentModal{
      .modal-dialog{
        max-width: 700px !important;
        .modal-content{
          .modalInner{
            .modalBody{
              height: 498px !important;
            }
          }
        }
      }
    }
    &.notificationModal{
      .modal-dialog{
        max-width: 500px !important;
        .modal-content{
          .modalInner{
            .modalBody{
              .main{
                max-height: 600px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1279px){
  .modal {
    &.viewModal, &.downloadModal{
      .modal-dialog {
        max-width: 651px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
              margin-bottom: 18px !important;
              p{
                font-size: $FONT_18 !important;
              }
            }
            .modalBody{
              .searchBar{
                max-width: 450px !important;
              }
              .fileInfoBar{
                ul{
                  li{
                    .fileName{
                      font-size: $FONT_18 !important;
                    }
                    .authorName{
                      font-size: $FONT_14 !important;
                    }
                    .data{
                      font-size: $FONT_16 !important;
                    }
                  }
                }
              }
              .table-content{
                .table-scroll{
                  max-height: 272px !important;
                }
              }
            }
          }
        }
      }
    }
    &.viewDocumentModal{
      .modal-dialog{
        max-width: 700px !important;
        .modal-content{
          .modalInner{
            .modalBody{
              height: 400px !important;
            }
          }
        }
      }
    }
    &.grantedModal{
      .modal-dialog{
          max-width: 700px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
                margin-bottom: 20px !important;
                p{
                    margin: 0;
                    font-size: $FONT_18 !important;
                }
              .modalClosebtn{
                width: 34px;
                height: 34px;
              }
            }
            .modalBody{
                float: left;
                width: 100%;
                .fileInfoBar{
                    margin-bottom: 18px !important;
                    p{
                        margin: 0;
                    }
                    ul{
                        li{
                            margin-right: 20px  !important;
                            .fileImg{
                                width: 40px;
                                height: 40px;
                            }
                            .fileName{
                                font-size: $FONT_18 !important;
                                color: $TEXT_SECONDARY_900;
                                font-family: $SFPRODESPLAY;
                            }
                        }
                    }
                }
                .searchBar{
                    margin-bottom: 18px !important;
                    max-width: 450px !important;
                    width: 100%;
                }
                .table-content{
                    .table-scroll{
                        max-height: 400px !important;
                        table{
                            min-width: 100%;
                            thead tr{
                                th{
                                  font-size: $FONT_14 !important;
                                    vertical-align: middle;
                                    div{
                                        display: flex;
                                        align-items: center;
                                        p{
                                            margin: 0;
                                        }
                                    }
                                    &:nth-child(3){
                                      min-width: 170px;
                                      width: 170px;
                                    }
                                    &:last-child{
                                      .action{
                                        display: none;
                                      }
                                      .expier{
                                          display: block !important;
                                      }
                                    }
                                }
                            }
                            tbody tr td{
                                padding: 8px 12px !important;
                                font-size: $FONT_14;
                                .email{
                                  font-size: $FONT_12;
                                }
                                &:nth-child(3){
                                  p{
                                    max-width: 170px;
                                  }
                                }
                            } 
                        }
                    }
                }
            }
          }
        }
      }
    }
    &.requestedModal{
      .modal-dialog{
          max-width: 700px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
                margin-bottom: 20px !important;
                p{
                    font-size: $FONT_18 !important;
                }
              .modalClosebtn{
                width: 34px !important;
                height: 34px !important;
              }
            }
            .modalBody{
                .fileInfoBar{
                    margin-bottom: 18px !important;
                    p{
                        margin: 0;
                    }
                    ul{
                        li{
                          margin-right: 20px !important;
                            .fileName{
                                font-size: $FONT_18 !important;
                            }
                        }
                    }
                }
                .searchBar{
                    margin-bottom: 18px !important;
                    max-width: 450px !important;
                    width: 100%;
                }
                .table-content{
                    .table-scroll{
                        max-height: 400px !important;
                        table{
                            min-width: 100%;
                            thead tr{
                                th{
                                  font-size: $FONT_14 !important;
                                    vertical-align: middle;
                                    &:last-child{
                                      width: 60px;
                                      min-width: 60px;
                                      div{
                                        display: none;
                                      }
                                      div{
                                        p{
                                          display: none;
                                        }
                                      }
                                    }
                                    &:nth-child(3){
                                      width: 160px;
                                      min-width: 160px;
                                    }
                                    div{
                                        display: flex;
                                        align-items: center;
                                        p{
                                            margin: 0;
                                        }
                                      }
                                }
                            }
                            tbody tr td{
                                padding: 8px 12px !important;
                                font-size: $FONT_14;
                                .email{
                                  font-size: $FONT_12;
                                }
                                &:nth-child(3){
                                  width: 160px;
                                  min-width: 160px;
                                }
                            } 
                        }
                    }
                }
            }
          }
        }
      }
    }
    &.notificationModal{
      .modal-dialog{
        max-width: 500px !important;
        .modal-content{
          .modalInner{
            .modalBody{
              .main{
                max-height: 600px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1439px){
  .modal {
    &.viewModal, &.downloadModal{
      .modal-dialog {
        max-width: 800px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
              margin-bottom: 18px !important;
              p{
                font-size: $FONT_18 !important;
              }
            }
            .modalBody{
              .searchBar{
                max-width: 450px !important;
              }
              .fileInfoBar{
                ul{
                  li{
                    .fileName{
                      font-size: $FONT_18 !important;
                    }
                    .authorName{
                      font-size: $FONT_14 !important;
                    }
                    .data{
                      font-size: $FONT_16 !important;
                    }
                  }
                }
              }
              .table-content{
                .table-scroll{
                  max-height: 312px !important;
                }
              }
            }
          }
        }
      }
    }
    &.viewDocumentModal{
      .modal-dialog{
        max-width: 700px !important;
        .modal-content{
          .modalInner{
            .modalBody{
              height: 500px !important;
            }
          }
        }
      }
    }
    &.grantedModal{
      .modal-dialog{
          max-width: 800px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
                margin-bottom: 20px !important;
                p{
                    margin: 0;
                    font-size: $FONT_18 !important;
                }
              .modalClosebtn{
                width: 34px;
                height: 34px;
              }
            }
            .modalBody{
                float: left;
                width: 100%;
                .fileInfoBar{
                    margin-bottom: 18px !important;
                    p{
                        margin: 0;
                    }
                    ul{
                        li{
                            margin-right: 20px  !important;
                            .fileImg{
                                width: 40px;
                                height: 40px;
                            }
                            .fileName{
                                font-size: $FONT_18 !important;
                                color: $TEXT_SECONDARY_900;
                                font-family: $SFPRODESPLAY;
                            }
                        }
                    }
                }
                .searchBar{
                    margin-bottom: 18px !important;
                    max-width: 450px !important;
                    width: 100%;
                }
                .table-content{
                    .table-scroll{
                        max-height: 400px !important;
                        table{
                            min-width: 100%;
                            thead tr{
                                th{
                                  font-size: $FONT_14 !important;
                                    vertical-align: middle;
                                    div{
                                        display: flex;
                                        align-items: center;
                                        p{
                                            margin: 0;
                                        }
                                    }
                                    &:nth-child(3){
                                      min-width: 170px;
                                      width: 170px;
                                    }
                                    &:last-child{
                                      .action{
                                        display: none;
                                      }
                                      .expier{
                                          display: block !important;
                                      }
                                    }
                                }
                            }
                            tbody tr td{
                                padding: 8px 12px !important;
                                font-size: $FONT_14;
                                .email{
                                  font-size: $FONT_12;
                                }
                                &:nth-child(3){
                                  p{
                                    max-width: 170px;
                                  }
                                }
                            } 
                        }
                    }
                }
            }
          }
        }
      }
    }
    &.requestedModal{
      .modal-dialog{
          max-width: 800px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
                margin-bottom: 20px !important;
                p{
                    font-size: $FONT_18 !important;
                }
              .modalClosebtn{
                width: 34px !important;
                height: 34px !important;
              }
            }
            .modalBody{
                .fileInfoBar{
                    margin-bottom: 18px !important;
                    p{
                        margin: 0;
                    }
                    ul{
                        li{
                          margin-right: 20px !important;
                            .fileName{
                                font-size: $FONT_18 !important;
                            }
                        }
                    }
                }
                .searchBar{
                    margin-bottom: 18px !important;
                    max-width: 450px !important;
                    width: 100%;
                }
                .table-content{
                    .table-scroll{
                        max-height: 400px !important;
                        table{
                            min-width: 100%;
                            thead tr{
                                th{
                                  font-size: $FONT_14 !important;
                                    vertical-align: middle;
                                    &:last-child{
                                      div{
                                        display: none;
                                      }
                                      div{
                                        p{
                                          display: none;
                                        }
                                      }
                                    }
                                    &:nth-child(3){
                                      width: 220px;
                                      min-width: 220px;
                                    }
                                    div{
                                        display: flex;
                                        align-items: center;
                                        p{
                                            margin: 0;
                                        }
                                      }
                                }
                            }
                            tbody tr td{
                                padding: 8px 12px !important;
                                font-size: $FONT_14;
                                .email{
                                  font-size: $FONT_12;
                                }
                                &:nth-child(3){
                                  width: 220px;
                                      min-width: 220px;
                                }
                            } 
                        }
                    }
                }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px){
  .modal {
    &.viewModal, &.downloadModal{
      .modal-dialog {
        max-width: 950px !important;
        .modal-content{
          .modalInner{
            .modalHeader{
              margin-bottom: 18px !important;
              p{
                font-size: $FONT_18 !important;
              }
            }
            .modalBody{
              .searchBar{
                max-width: 600px !important;
              }
              .fileInfoBar{
                ul{
                  li{
                    .fileName{
                      font-size: $FONT_18 !important;
                    }
                    .authorName{
                      font-size: $FONT_14 !important;
                    }
                    .data{
                      font-size: $FONT_16 !important;
                    }
                  }
                }
              }
              .table-content{
                .table-scroll{
                  max-height: 480px !important;
                }
              }
            }
          }
        }
      }
    }
    &.viewDocumentModal{
      .modal-dialog{
        max-width: 900px !important;
        .modal-content{
          .modalInner{
            .modalBody{
              height: 600px !important;
            }
          }
        }
      }
    }
  }
}