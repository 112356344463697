@import '../../../Assets/scss/variable.scss';

.btn {
  &.iconBtn {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-family: $SFPRODESPLAY_MEDIUM;
    color: $TEXT_WHITE;
    border: 0 !important;
    font-size: $FONT_16 !important;
    border-radius: 5px !important;
    padding: 10px 15px !important;
    box-shadow: none;
    text-align: center;

    i {margin-right: 12px;
      font-size: $FONT_18 !important;
    }

    &:hover{}
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
