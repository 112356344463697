@import './Assets/scss/variable.scss';

a {
  text-decoration: none;
}

.noData {
  .container {
    p {
      width: 100%;
      max-width: 100%;
    }
  }
}

.dropdown-item {
  font-family: $SFPRODESPLAY_MEDIUM;
}

.documentManagement {
  .documentContainer {
    .detailMainContainer {
      overflow-y: hidden;
    }
  }
}

.detailMainContainer {
  overflow-y: auto;
  width: 100%;
  float: left;
  scrollbar-color: transparent;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
    height: 0px !important;
  }

  #scrollablePage::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -ms-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -o-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}


#scrollablePage {
  overflow-y: auto;
  scrollbar-color: #e4e4e4 transparent;
}

#scrollablePage::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 5px !important;
}

#scrollablePage::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -ms-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -o-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

#scrollable {
  overflow-y: auto;
  scrollbar-color: #e4e4e4 transparent;
  scrollbar-width: thin;
}

#scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
  height: 5px !important;
}

#scrollable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -ms-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -o-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

#textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    white-space: normal;
    text-overflow: unset;
  }
}

#textOverflowMultiLine {
  max-height: 40px;
  line-height: 16px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.main-page {
  float: left;
  position: relative;
  width: 100%;
  padding-left: 200px;
  overflow: hidden;

  .page {
    float: left;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $BG_OFWHITE;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08) inset;
    -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08) inset;
    -moz-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08) inset;
    overflow: auto;
    scrollbar-color: #e4e4e4 transparent;
    scrollbar-width: thin;
  }

  .page::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  .page::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .pageTitle {
    margin: 0;
    position: fixed;
    left: 200px;
    top: 24px;
    padding-left: 30px;
    z-index: 9;
    color: $TEXT_WHITE;
    font-size: $FONT_28;
    font-family: $SFPRODESPLAY_MEDIUM;
  }
}

.table-content {
  border: 1px solid $BG_GRAY;
  background-color: $BG_WHITE;
  border-radius: 10px;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 10px;
}

.table-scroll table {
  width: 100%;
  min-width: 100%;
  margin: auto;
  border-collapse: collapse;
  // border-spacing: 0 4px;
  padding-right: 10px;
}

.table-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.table-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -ms-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -o-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.table-wrap {
  position: relative;
}

.table-scroll th,
.table-scroll td {
  border: 0;
  vertical-align: middle;
}

.table-scroll thead tr {
  width: 100%;
}

.table-scroll tr {
  width: 100%;
  cursor: pointer;
}

.table-scroll tbody tr td {
  text-align: left;
  font-size: $FONT_16;
  color: $TEXT_SECONDARY_400;
  font-family: $SFPRODESPLAY_MEDIUM;
  padding: 10px;
  background-color: $BG_WHITE;
  box-shadow: inset 0px -1px 0px #EDEDED;

  p {
    margin: 0;
  }

  .responsive_actionBoxMain {
    display: none;
  }

  .revokeResponsive {
    display: none;
  }

  .acceptRejectResponsive {
    display: none;
  }
}

.table-scroll thead tr th {
  font-family: $SFPRODESPLAY_MEDIUM;
  font-size: $FONT_16;
  color: $TEXT_SECONDARY_800;
  font-weight: 400 !important;
  font-weight: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: left;
  z-index: 9999;
  vertical-align: top;
  padding: 16px 10px 13px 10px;
  box-shadow: inset 0px -1px 0px #EDEDED;
  background-color: $BG_WHITE;
}

@media only screen and (min-width:768px) and (max-width:1024px) {
  .table-scroll table {
    min-width: 100%;

    tbody {
      tr {
        td {
          .actionBoxMain {
            display: none !important;
          }

          .responsive_actionBoxMain {
            display: block;
          }

          .revokeAction {
            display: none !important;
          }

          .acceptReject {
            display: none !important;
          }

          .revokeResponsive {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .acceptRejectResponsive {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .titleBar {
    .buttonOption {
      .dateDropdown {
        .dropdown-toggle {
          font-size: $FONT_14 !important;
        }
      }

      .regionFilter {
        .dropdown {
          .btn {
            font-size: $FONT_14;
          }
        }
      }

      .tagBtn {
        font-size: $FONT_14 !important;
      }
    }
  }
}

@media (max-width: 1279px) {

  .main-page {
    padding-left: 0;

    .page {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none;
    }

    .pageTitle {
      top: 18px;
      left: 64px;
      padding-left: 0;
      font-size: $FONT_24;
    }
  }

  .table-scroll table {
    min-width: 100%;

    tbody {
      tr {
        td {
          .actionBoxMain {
            display: none !important;
          }

          .responsive_actionBoxMain {
            display: block;
          }

          .revokeAction {
            display: none !important;
          }

          .acceptReject {
            display: none !important;
          }

          .revokeResponsive {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .acceptRejectResponsive {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.buttonOption .dateDropdown .dropdown-menu {
  z-index: 9;
}

.dropdown-menu {}

//header
.headerAdmin,
.technicalTeamHeader,
.userHeader {
  .header {
    z-index: 999;

    .navbar {
      .profiledropdown {
        justify-content: space-between;

        .pageTitle {
          font-family: $SFPRODESPLAY_MEDIUM;
          font-size: $FONT_28;
          color: $TEXT_WHITE;
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 1279px) {

  .headerAdmin,
  .technicalTeamHeader,
  .userHeader {
    .outside {
      display: flex;
    }

    .header {
      background-color: transparent;
      width: auto;

      .navbar {
        .navbar-brand {
          width: 127px;
          // height: 52px;
        }

        .navbar-toggler {
          position: relative;

          &.notification {
            span {
              &::before {
                content: '';
                position: absolute;
                right: -3px;
                z-index: 10;
                bottom: 2px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $TEXT_ERROR_500;
              }
            }
          }

          &:focus {
            box-shadow: none;
          }

          width: 100%;
          height: 100%;
          display: block;
          position: fixed;
          left: 200px;
          top: 100%;
          z-index: 9;
          padding: 0;
          border: 0;
          border-radius: 0;

          &.collapsed {
            width: 20px;
            height: 16px;
            display: block;
            position: fixed;
            left: 15px;
            top: 25px;
            z-index: 9;
            padding: 0;
            border: 0;
            border-radius: 0;

            &::before {
              background-color: $BG_WHITE !important;
            }

            span {
              background-color: $BG_WHITE !important;
              position: relative;
            }

            &::after {
              opacity: 1;
              background-color: $BG_WHITE !important;
            }
          }

          &:after {
            position: fixed;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $BG_PRIMARY;
            opacity: 0.3;
          }

          &:focus {
            outline: none;
          }

          // &::after {
          //   position: absolute;
          //   left: -2px;
          //   top: -3px;
          //   content: "Button";
          //   color: $TEXT_PRIMARY;
          //   font-weight: 600;
          //   font-size: $FONT_24;
          // }

          span,
          &::before {
            display: none;
          }

          &.collapsed {
            &::after {
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              content: "";
              width: 100%;
              height: 3px;
              border-radius: 2px;
              background-color: $BG_WHITE;
            }

            &::before {
              display: block;
              position: absolute;
              left: 0;
              bottom: 1px;
              content: "";
              width: 100%;
              height: 3px;
              border-radius: 2px;
              background-color: $BG_WHITE;
            }

            span {
              background-image: none;
              width: 100%;
              height: 3px;
              border-radius: 2px;
              background-color: $BG_WHITE;
              display: block;
              position: relative;
              top: -1px;
            }
          }
        }

        .navbar-collapse {
          background-color: $BG_WHITE;
          position: absolute;
          top: 0px;
          left: 0;
          width: 190px;
          height: 100%;
          padding: 10px !important;
          margin: 0 !important;
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
          transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          box-shadow: none;

          &.show {
            transform: translateX(0%);
            -webkit-transform: translateX(0%);
            -moz-transform: translateX(0%);
            -ms-transform: translateX(0%);
            -o-transform: translateX(0%);
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
          }

          .navbar-nav {
            .nav-link {
              text-align: left;
              display: flex;
              align-items: center;
              padding: 8px 18px;
              width: 100%;
              float: left;
              margin-bottom: 8px;

              i {
                margin-right: 10px;
                font-size: $FONT_18;

                &.notification {
                  &::after {
                    content: '';
                    position: absolute;
                    top: 0px;
                    right: 2px;
                    width: 10px !important;
                    height: 10px !important;
                    border-radius: 50%;
                    background-color: $TEXT_PRIMARY;
                  }
                }
              }
            }

            .navTabMain {
              .navTab {
                width: 140px;

                .nav-link {
                  .linkBtn {
                    i {
                      font-size: $FONT_24 !important;
                    }

                    span {
                      font-size: $FONT_14;
                    }
                  }
                }
              }
            }
          }
        }

        .profiledropdown {
          position: fixed;
          right: 0;
          top: 0;
          width: 100% !important;
          padding: 0 15px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          min-height: 60px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -ms-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -o-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

          .dropdown {
            margin: 0;
            padding: 0;

            .dropdown-toggle {
              padding-right: 0;

              span {
                display: none;
              }
            }
          }

          .pageTitle {
            font-family: $SFPRODESPLAY_MEDIUM;
            font-size: $FONT_24;
            color: $TEXT_WHITE;
            padding-left: 64px;
            margin: 0;
            display: flex;
            align-items: center;
          }
        }

        // .outside{
        //   position: fixed;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   z-index: 1;
        //   background-color: bisque;
        // }
      }
    }
  }
}

@media (max-width : 767px) {

  .headerAdmin,
  .technicalTeamHeader,
  .userHeader {
    .header {
      .navbar {
        .navbar-brand {
          height: 70px;
          min-height: 60px;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .navbar-collapse {
          // width: 100%;
          transition: all 0.1s ease;
          -webkit-transition: all 0.1s ease;
          -ms-transition: all 0.1s ease;
          -moz-transition: all 0.1s ease;
          -o-transition: all 0.1s ease;
          box-shadow: none;

          &.show {
            transition: all 0.1s ease;
            -webkit-transition: all 0.1s ease;
            -ms-transition: all 0.1s ease;
            -moz-transition: all 0.1s ease;
            -o-transition: all 0.1s ease;
          }

          &.show {
            width: 100vw;
            padding: 0 !important;
            z-index: 8;
          }

          .navbar-nav {
            .navTabMain {
              padding-top: 0px;

              .navTab {
                width: 100%;
                padding: 13px 26px;
              }

              .nav-link {
                .linkBtn {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;

                  i {
                    margin-right: 14px !important;

                    &.notification {
                      &::after {
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: 2px;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $TEXT_PRIMARY;
                      }
                    }
                  }

                  span {
                    padding-top: 0;
                    font-size: $FONT_16 !important;
                  }
                }
              }

              .profileTab {
                padding: 26px;

                .content {
                  padding: 0 16px;
                  align-items: flex-start;

                  p {
                    display: none;
                  }

                  .custombtn {
                    display: none;
                  }

                  .dropdown {
                    .dropdown-menu {
                      top: auto;
                      left: 100px;
                      bottom: 50px;
                    }

                    .dropdown-toggle {
                      display: flex;
                      align-items: center;

                      .sidebarProfile {
                        margin: 0;
                        margin-right: 12px;
                        width: 48px;
                        height: 48px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .navbar-toggler {
          &::after {
            transform: 0.15s;
            font-family: 'Aviagen' !important;
            color: $TEXT_SECONDARY_700;
            position: fixed;
            content: '\e907';
            width: 24px;
            height: 24px;
            font-size: $FONT_24;
            left: 15px;
            top: 20px;
            background-color: transparent;
            opacity: 1;
            z-index: 9;
          }
        }
      }
    }
  }

}

// @media screen and (min-width: 1440px) and (max-width: 1919px) and (max-height: 1079px){
//   .headerAdmin, .technicalTeamHeader,.userHeader {
//     .header{
//       .navbar{
//         .navbar-collapse{
//           .navbar-nav{
//             .navTabMain{
//               .navTab{
//                 overflow-y: auto;
//               }
//             }
//           } 
//         }
//       } 
//     } 
//   }
// }
@media screen and (min-width: 768px) and (max-width: 1279px) {

  .headerAdmin,
  .technicalTeamHeader,
  .userHeader {
    .header {

      // width: 170px;
      .navbar {
        .navbar-brand {
          min-height: auto;
        }

        .navbar-collapse {
          padding: 10px;

          .navbar-nav {
            .navTabMain {
              padding-top: 16px;

              .navTab {
                width: 118px;
              }

              .nav-link {
                padding: 10px;

                .linkBtn {
                  font-size: $FONT_14 !important;
                }
              }

              .profileTab {
                .content {
                  .dropdown {
                    .dropdown-toggle {
                      .sidebarProfile {
                        width: 50px;
                        height: 50px;
                      }

                      span {
                        max-width: 90px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .profiledropdown {
          width: calc(100% - 170px);
        }
      }
    }
  }
}

// @media screen and (min-width: 1440px) and (max-height: 900px){
//   .headerAdmin, .technicalTeamHeader,.userHeader {
//     .header{
//       .navbar{
//         .navbar-brand{
//           min-height: auto;
//           padding: 0px 16px 16px 16px;
//         }
//         .navbar-collapse{
//           padding: 10px;
//           .navbar-nav{
//             .navTabMain{
//               height: calc(100% - 90px);
//               .navTab{
//                 overflow-y: auto;
//                 scrollbar-color: #e4e4e4 transparent;
//                   scrollbar-width: thin;

//                 &::-webkit-scrollbar {
//                   -webkit-appearance: none;
//                   width: 7px;
//                   height: 7px;
//                 }

//                 &::-webkit-scrollbar-thumb {
//                   border-radius: 4px;
//                   background-color: rgba(0, 0, 0, 0.1);
//                   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
//                 }
//               }
//             }
//           } 
//         }
//       } 
//     } 
//   }
// }
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .main-page {

    // padding-left: 170px;
    .pageTitle {
      // left: 170px;
    }
  }

  .headerAdmin,
  .technicalTeamHeader,
  .userHeader {
    .header {

      // width: 170px;
      .navbar {
        .navbar-brand {
          min-height: auto;
        }

        .navbar-collapse {
          padding: 10px;

          .navbar-nav {
            .navTabMain {
              padding-top: 16px;

              .navTab {
                width: 128px;
              }

              .nav-link {
                padding: 10px;

                .linkBtn {
                  font-size: $FONT_14 !important;
                }
              }

              .profileTab {
                .content {
                  .dropdown {
                    .dropdown-toggle {
                      .sidebarProfile {
                        width: 68px;
                        height: 68px;
                      }

                      span {
                        max-width: 90px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .profiledropdown {
          width: calc(100% - 200px);
        }
      }
    }
  }
}

@media screen and (max-width: 1919px) and (max-height:720px) {

  .headerAdmin,
  .technicalTeamHeader,
  .userHeader {
    .header {
      .navbar {
        .navbar-brand {}

        .navbar-collapse {
          .navbar-nav {
            .navTabMain {
              .navTab {
                width: 148px;
              }

              .nav-link {
                padding: 16px 20px;
                margin-bottom: 18px;

                .linkBtn {

                  width: 132px;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;

                  span {
                    padding-top: 0;
                    padding-left: 10px;
                    text-align: left;
                  }
                }
              }

              .profileTab {
                padding: 16px 0;

                .content {
                  .dropdown {
                    .dropdown-toggle {
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .sidebarProfile {
                        width: 50px;
                        height: 50px;
                        margin: 0;
                        margin-right: 4px;
                      }

                      span {
                        max-width: 80px;
                        padding-left: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 767px) {
  // .userHeader{
  //   .header{
  //     .navbar{
  //       .navbar-collapse{
  //         .navbar-nav{
  //           .navTabMain{
  //             overflow-y: auto;
  //           }
  //         } 
  //       } 
  //     } 
  //   } 
  // } 
}

// Sidebar filter css
.tagMain {
  position: relative;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 99;

  .tagOption {
    height: auto;
    width: 100%;
    position: absolute;
    top: -30px;
    right: -100%;
    width: 304px;
    padding: 30px 24px;
    display: flex;
    transition: all 0.7s ease-in-out;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    background-color: $BG_WHITE;
    box-shadow: 0px 16px 48px rgba(0, 120, 219, 0.05);
    -webkit-box-shadow: 0px 16px 48px rgba(0, 120, 219, 0.05);
    -moz-box-shadow: 0px 16px 48px rgba(0, 120, 219, 0.05);
    -ms-box-shadow: 0px 16px 48px rgba(0, 120, 219, 0.05);
    -o-box-shadow: 0px 16px 48px rgba(0, 120, 219, 0.05);

    &.showFilter {
      right: -30px;
    }

    .tagData {
      width: 100%;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: $FONT_22;
          font-family: $SFPRODESPLAY_MEDIUM;
          color: $TEXT_SECONDARY_800;
          margin: 0;
        }

        .btn {
          padding: 0 !important;
          font-family: $SFPRODESPLAY_MEDIUM;
          background-color: transparent;
          min-width: auto;
          width: auto;
          min-height: auto !important;
        }

        .clearBtn {
          margin-right: 20px;
          color: $TEXT_ERROR !important;
        }

        .closeBtn {
          color: $TEXT_SECONDARY_200;
          padding: 10px !important;

          i {
            margin-right: 0;
          }
        }

        div {
          display: flex;
          align-items: center;
        }
      }

      .tagBody {
        float: left;
        width: 100%;
        padding-top: 10px;
        height: 100%;

        .innerBody {
          height: calc(100% - 88px);
          float: left;
          width: 100%;
          scrollbar-width: 0px !important;

          .accordion {
            .accordion-item {
              margin-bottom: 18px;
              border-radius: 8px;
              border: 1px solid #EDEDED;

              .accordion-button {
                padding: 12px 16px;
                border-radius: 8px;
                font-family: $SFPRODESPLAY_MEDIUM;
                font-size: $FONT_16;
                color: $TEXT_SECONDARY_300;
                box-shadow: none;

                &:focus {
                  color: $TEXT_SECONDARY_300;
                }

                &:not(.collapsed) {
                  border-radius: 8px 8px 0 0;
                  background-color: #E0F5FF;
                }

                &::after {
                  content: '\e925';
                  position: relative;
                  font-family: 'Aviagen' !important;
                  background-image: none;
                  width: auto;
                  height: auto;
                  background-size: auto;
                  color: $TEXT_SECONDARY_900;
                  font-weight: bold;
                }

                .dotActive {
                  width: 6px;
                  height: 6px;
                  background-color: #0069B6;
                  border-radius: 5px;
                  margin-left: 5px;
                }
              }

              .accordion-collapse {
                .accordion-body {
                  padding: 8px;

                  .box {
                    p {
                      margin: 0;
                      font-family: $SFPRODESPLAY_MEDIUM;
                      font-size: $FONT_16;
                      color: $TEXT_SECONDARY_300;
                    }

                    ul {
                      list-style: none;
                      padding: 0;
                      margin: 0;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      flex-wrap: wrap;
                      width: 100%;

                      li {
                        width: auto;
                        margin-right: 6px;
                        margin-bottom: 8px;

                        div {
                          p {
                            margin: 0;
                            font-family: $SFPRODESPLAY_MEDIUM;
                            font-size: $FONT_16;
                            color: $TEXT_SECONDARY_400;
                          }

                          span {
                            font-size: 60px;
                            color: #006FBA;
                          }

                          cursor: pointer;
                          border-radius: 10px;
                          padding: 8px 10px;
                          border: 1px solid $BG_GRAY;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          flex-direction: column;

                          &.selected {
                            background-color: $BG_PRIMARY_50;
                            border: 1px solid $BG_PRIMARY_50;
                          }

                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                          }
                        }
                      }
                    }

                    &.Brands {
                      ul {

                        // justify-content: center;
                        li {
                          div {
                            width: 108px;
                            height: 108px;
                          }
                        }
                      }
                    }

                    &.Technical {
                      ul {
                        justify-content: center;

                        li {
                          width: 190px;

                          div {
                            // width: 108px;
                            // height: 108px;
                            flex-direction: row;

                            p {
                              text-align: center;
                            }
                          }

                          .icon-Eggs {
                            transform: rotate(180deg);
                          }
                        }
                      }
                    }

                    &.Generation {
                      ul {
                        li {
                          width: max-content;

                          div {
                            width: auto;
                            height: 40px;
                            padding: 8px 16px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .actionBox {
          float: left;
          width: 100%;
          position: relative;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 10px;

          .leftBtn {
            min-width: 100px !important;
            margin-right: 18px;
            background-color: $BG_WHITE !important;
            color: $TEXT_PRIMARY !important;
            border: 1px solid $BG_GRAY !important;
          }

          .rightBtn {
            min-width: 100px !important;
          }
        }
      }
    }
  }
}

.filterdBox {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .filterdMain {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    // max-height: 150px;
    // overflow-y: auto;
    scrollbar-color: #e4e4e4 transparent;

    &.filterdMain::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      height: 5px !important;
    }

    &.filterdMain::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      -ms-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      -o-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .filterdList {
      display: flex;
      align-items: center;
      background: #E0F5FF;
      border-radius: 10px;
      height: 34px;
      padding: 5px 8px;
      margin-bottom: 8px;
      margin-top: 8px;
      margin-right: 8px;

      .itemImg {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        font-size: $FONT_14;
        font-family: $SFPRODESPLAY;
        color: $TEXT_PRIMARY;
      }

      p {
        margin: 0;
        font-size: $FONT_14;
        font-family: $SFPRODESPLAY;
        color: $TEXT_PRIMARY;
      }

      .closeFilter {
        margin-left: 8px;
        width: auto !important;
        padding: 0 !important;
        height: 100%;
        background-color: transparent;

        i {
          margin-right: 0;
          font-size: $FONT_18 !important;
          color: $TEXT_SECONDARY_300 !important;
        }
      }

      .clearAll {
        min-width: auto;
        color: $TEXT_ERROR_500 !important;
        text-decoration: underline;
        background-color: transparent !important;
        padding: 8px !important;
      }
    }
  }
}


.ViewDocumentPage {
  .viewDocumentContainer {
    .content {
      .table-content {
        .table-scroll {
          table {
            tbody {
              td {
                .action {

                  // background-color: yellow;
                  .actionBtn {
                    &:last-child {
                      background: rgba(0, 105, 182, .05) !important;
                    }

                    .icon-Share_iOS_Export {
                      &::before {
                        color: $TEXT_PRIMARY;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

.icon-Share_iOS_Export {
  &::before {
    color: $TEXT_PRIMARY;
  }
}